<template>
  <div class="container flex-1 py-8 bg-white lg:pb-20 lg:pt-10 lg:px-10 max-w-7xl">
    <div class="prose-sm prose prose-indigo sm:max-w-none sm:prose">
      <h1>Privacy Policy</h1>
      <p>This document explains how we use your personal data.</p>
      <h1>How we use your personal data</h1>
      <h3>This section provides you with information about:</h3>
      <p>(a) what personal data we hold and process;</p>
      <p>
        (b) in respect of personal data that we did not collect from you directly, where we obtained that data from, and
        what types of data we have collected;
      </p>
      <p>(c) the purposes for which we may process your personal data; and</p>
      <p>(d) the legal grounds on which we process your data.</p>
      <p>We will not pass on your information to any third party without your explicit consent.</p>
      <h3>Contact data/Enquiry data.</h3>
      <p>
        We may process contact details that you provide to us (“contact data”). This contact data may include your name,
        address, telephone number, and email address, and may be provided through our website. We may use this contact
        data to manage our account with us and to contact you to discuss your use of our website, and how our website
        and content performed and functioned for you. We may also use this data to: (a) respond to any enquiries you
        make through our web site or our live web support platform, LiveChat! (b) provide you with a copy of or updates
        to our eBook.
      </p>
      <p>
        The legal basis for this processing is our legitimate interest in responding to your enquiries and ensuring the
        efficient administration and continued function of our website.
      </p>
      <h3>Profile data.</h3>
      <p>
        During the course of providing services to you, you may provide us with biographical information about yourself
        (“profile data”). This profile data may include your contact data, details of your social media presence,
        biographical information which you upload and a profile picture.
      </p>
      <p>
        We may use this data to provide you with services from our web site and to monitor and administer your account
        with us. If you are a standard account holder the legal basis for our processing is legitimate interest.
      </p>
      <p>
        If you are a PRO account holder or applying for a PRO account, your profile data may be processed in this way
        for the purposes of performing our contract with you, to provide services through our web site, or in taking
        steps at your request prior to entering into a contract.
      </p>
      <p>
        Where you have provided your consent for us to do so, we may also: (a) publish your profile picture and
        biographical information publicly; and, (b) use this data for the purposes of carrying out marketing activities,
        specifically in order to tailor the offers, advertisements and promotions that we bring to your attention both
        when we contact you, and through our website.
      </p>
      <p>
        If you opt-in to our mailing list, you will receive emails that may include company news, updates, related
        product or service information. If at any time you wish to unsubscribe from receiving future emails, we include
        detailed unsubscribe instructions appear at the bottom of each e-mail.
      </p>
      <h3>Sensitive Personal Data.</h3>
      <p>
        During the course of using our services, you may provide us with sensitive information about yourself
        (“sensitive data”). However, we will only process this sensitive data if you consent to us doing so.
      </p>
      <h3>Transaction data.</h3>
      <p>
        We may process information relating to any payments made to you through our website (“transaction data”). The
        transaction data may include your contact details, your bank account details, and the transaction details. The
        transaction data may be processed for the purposes of processing these payments and keeping proper records of
        those transactions.
      </p>
      <p>
        The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at
        your request, to enter into such a contract and our legitimate interests, namely our interest in the proper
        administration of our website and business.
      </p>
      <h3>Website data.</h3>
      <p>
        We may process data about your use of our website and services (“website data”). The website data may include
        your IP address, geographical location, browser type and version, operating system, referral source, length of
        visit, page views and website navigation paths, as well as information about the timing, frequency and pattern
        of your service use. The source of the website data is our analytics tracking system. This website data may be
        processed for the purposes of analysing the use of the website and services.
      </p>
      <p>
        The legal basis for this processing is our legitimate interests, namely monitoring and improving our website and
        services.
      </p>
      <h3>Notification data.</h3>
      <p>
        We may process information that you provide to us for the purpose of subscribing to our newsletters
        (“notification data”). The notification data may be processed for the purposes of sending you newsletters.
      </p>
      <p>The legal basis for this processing is consent.</p>
      <h3>Correspondence data.</h3>
      <p>
        We may process information contained in or relating to any communication that you send to us (“correspondence
        data”). The correspondence data may include the communication content and metadata associated with the
        communication. Our website will generate the metadata associated with communications made using the website
        contact forms. The correspondence data may be processed for the purposes of communicating with you and
        record-keeping.
      </p>
      <p>
        The legal basis for this processing is our legitimate interests, namely the proper administration of our website
        and business and communications with users.
      </p>
      <h3>Other processing activities.</h3>
      <p>
        In addition to the specific purposes for which we may process your personal data set out above, we may also
        process any of your personal data where such processing is necessary for compliance with a legal obligation to
        which we are subject, or in order to protect your vital interests or the vital interests of another natural
        person.
      </p>
      <h3>Providing your personal data to others</h3>
      <h3>Our partner service providers.</h3>
      <p>
        We may share your data to our partners who manage and support the technology we provide through our web site
        (such as WordPress and Hubspot).
      </p>
      <p>
        We may use third party service providers to help us operate our business and our web site or administer
        activities on our behalf, such as sending out newsletters or surveys. We may share your information with these
        third parties for those limited purposes provided that you have given us your permission.
      </p>
      <h3>Our professional advisers.</h3>
      <p>
        We may disclose your personal data to our professional advisers insofar as reasonably necessary for the purposes
        of managing risks, obtaining professional advice and managing legal disputes.
      </p>
      <h3>Where we provide your personal data to any third party.</h3>
      <p>
        Where we share your personal data with any third party, we will ensure this processing is protected by
        appropriate safeguards including a suitable data processing agreement with that third party.
      </p>
      <h3>To comply with legal obligations.</h3>
      <p>
        In addition to the specific disclosures of personal data detailed above, we may also disclose your personal data
        where such disclosure is necessary for compliance with a legal obligation we have to comply with, or in order to
        protect your vital interests or the vital interests of another individual.
      </p>
      <h2>Transfers of your personal data outside of the European Economic Area</h2>
      <p>
        Where your personal data is transferred outside of the EEA, we will ensure that either (a) The European
        Commission has made an “adequacy decision” with respect to the data protection laws of the country to which it
        is transferred, or (b) we have entered into a suitable data processing agreement with the third party situated
        in that country to ensure the adequate protection of your data. In all cases, transfers outside of the EEA will
        be protected by appropriate safeguards.
      </p>
      <h2>Retaining and deleting personal data</h2>
      <p>
        Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for
        that purpose or those purposes.
      </p>
      <p>
        Unless we contact you and obtain your consent for us to retain your personal data for a longer period, we will
        retain and delete your personal data as follows:
      </p>
      <p>
        (a) Contact data will be retained for 3 years following the date of our last contact or dealing with you, at the
        end of which period it will be deleted from our systems.
      </p>
      <p>
        (b)Profile data will be retained for 2 years following the date of our last contact or dealing with you, at the
        end of which period it will be deleted from our systems.
      </p>
      <p>
        (c) Sensitive data will be retained for 2 years following the date of our last contact or dealing with you, at
        the end of which period it will be deleted from our systems.
      </p>
      <p>
        (d) Website data will be retained for 2 years following the date of our last contact or dealing with you, at the
        end of which period it will be deleted from our systems.
      </p>
      <p>
        (e) Enquiry data will be retained for 2 years following the date of our last contact or dealing with you, at the
        end of which period it will be deleted from our systems.
      </p>
      <p>
        (f) Notification data will be retained for 2 years following the date of our last contact or dealing with you,
        at the end of which period it will be deleted from our systems.
      </p>
      <p>
        (g) Correspondence data will be retained for 2 years following the date of our last contact or dealing with you,
        at the end of which period it will be deleted from our systems.
      </p>
      <h3>
        We may retain your personal data where such retention is necessary for compliance with a legal obligation to
        which we are subject, or in order to protect your vital interests or the vital interests of another natural
        person.
      </h3>
      <h2>Amendments</h2>
      <h3>We may update this policy from time to time by publishing a new version on our website.</h3>
      <h3>You should check this page occasionally to ensure you are happy with any changes to this policy.</h3>
      <h3>We may notify you of changes to this policy by email.</h3>
      <h2>Your rights</h2>
      <h3>
        You may instruct us to provide you with any personal information we hold about you; provision of such
        information will be subject to:
      </h3>
      <p>(a) your request not being found to be unfounded or excessive, in which case a charge may apply; and</p>
      <p>
        (b) the supply of appropriate evidence of your identity (for this purpose, we will usually accept a photocopy of
        your passport certified by a solicitor or bank plus an original copy of a utility bill showing your current
        address).
      </p>
      <h3>We may withhold personal information that you request to the extent permitted by law.</h3>
      <h3>The rights you have under data protection law are:</h3>
      <p>(a) the right to access;</p>
      <p>(b) the right to rectification;</p>
      <p>(c) the right to erasure;</p>
      <p>(d) the right to restrict processing;</p>
      <p>(e) the right to object to processing;</p>
      <p>(f) the right to data portability;</p>
      <p>(g) the right to complain to a supervisory authority; and</p>
      <p>(h) the right to withdraw consent.</p>
      <h3>Your right to access data.</h3>
      <p>
        You have the right to ask us to confirm whether or not we process your personal data and, to have access to the
        personal data, and any additional information. That additional information includes the purposes for which we
        process your data, the categories of personal data we hold and the recipients of that personal data. You may
        request a copy of your personal data. The first copy will be provided free of charge, but we may charge a
        reasonable fee for additional copies.
      </p>
      <h3>Your right to rectification.</h3>
      <p>
        If we hold any inaccurate personal data about you, you have the right to have these inaccuracies rectified.
        Where necessary for the purposes of the processing, you also have the right to have any incomplete personal data
        about you completed.
      </p>
      <h3>Your right to erasure.</h3>
      <p>
        In certain circumstances you have the right to have personal data that we hold about you erased. This will be
        done without undue delay. These circumstances include the following: it is no longer necessary for us to hold
        those personal data in relation to the purposes for which they were originally collected or otherwise processed;
        you withdraw your consent to any processing which requires consent; the processing is for direct marketing
        purposes; and the personal data have been unlawfully processed. However, there are certain general exclusions of
        the right to erasure, including where processing is necessary: for exercising the right of freedom of expression
        and information; for compliance with a legal obligation; or for establishing, exercising or defending legal
        claims.
      </p>
      <h3>Your right to restrict processing.</h3>
      <p>
        In certain circumstances you have the right for the processing of your personal data to be restricted. This is
        the case where: you do not think that the personal data we hold about you is accurate; your data is being
        processed unlawfully, but you do not want your data to be erased; it is no longer necessary for us to hold your
        personal data for the purposes of our processing, but you still require that personal data in relation to a
        legal claim; and you have objected to processing, and are waiting for that objection to be verified. Where
        processing has been restricted for one of these reasons, we may continue to store your personal data. However,
        we will only process it for other reasons: with your consent; in relation to a legal claim; for the protection
        of the rights of another natural or legal person; or for reasons of important public interest.
      </p>
      <h3>Your right to object to processing.</h3>
      <p>
        You can object to us processing your personal data on grounds relating to your particular situation, but only as
        far as our legal basis for the processing is that it is necessary for: the performance of a task carried out in
        the public interest, or in the exercise of any official authority vested in us; or the purposes of our
        legitimate interests or those of a third party. If you make an objection, we will stop processing your personal
        information unless we are able to: demonstrate compelling legitimate grounds for the processing, and that these
        legitimate grounds override your interests, rights and freedoms; or the processing is in relation to a legal
        claim.
      </p>
      <h3>Your right to object to direct marketing.</h3>
      <p>You may instruct us at any time not to process your personal information for marketing purposes.</p>
      <p>
        In practice, you will usually either expressly agree in advance to our use of your personal information for
        marketing purposes, or we will provide you with an opportunity to opt out of the use of your personal
        information for marketing purposes.
      </p>
      <p>
        You can object to us processing your personal data for direct marketing purposes. If you make an objection, we
        will stop processing your personal data for this purpose.
      </p>
      <h3>Your right to object for statistical purposes.</h3>
      <p>
        You can object to us processing your personal data for statistical purposes on grounds relating to your
        particular situation, unless the processing is necessary for performing a task carried out for reasons of public
        interest.
      </p>
      <h3>Automated data processing.</h3>
      <p>
        To the extent that the legal basis we are relying on for processing your personal data is consent, and where the
        processing is automated, you are entitled to receive your personal data from us in a structured, commonly used
        and machine-readable format. However, you may not have this right if it would adversely affect the rights and
        freedoms of others.
      </p>
      <h3>Complaining to a supervisory authority.</h3>
      <p>
        If you think that our processing of your personal data infringes data protection laws, you can lodge a complaint
        with a supervisory authority responsible for data protection. You may do this in the EU member state of your
        habitual residence, your place of work or the place of the alleged infringement.
      </p>
      <h2>Right to withdraw consent.</h2>
      <p>
        To the extent that the legal basis we are relying on for processing your personal data is consent, you are
        entitled to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before
        the withdrawal.
      </p>
      <h3>Exercising your rights.</h3>
      <p>
        You may exercise any of your rights in relation to your personal data by written notice to us in addition to the
        other methods specified above.
      </p>
      <h2>Cookie Policy</h2>
      <p>
        Cookies are small pieces of information stored by Your browser on Your computers hard drive. We use cookies to
        allow Us to understand who has seen which pages and advertisements, to determine how frequently particular pages
        are visited, to determine the most popular areas of the Site and generally in order to monitor usage of the
        Site. Most web browsers automatically accept cookies, though You do not have to. We do not control the use of
        cookies by third parties. If You wish to disable cookies then You can do so by adjusting Your browser settings
        although please note that by disabling cookies You may not be able to register with or use all features of the
        Site.
      </p>
      <p>
        For more information on cookies and how to disable them, You can consult the information provided by the
        Interactive Advertising Bureau at&nbsp;<a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.
      </p>
      <h2>Privacy Officer</h2>
      <p>
        We have appointed a data protection officer (Privacy Officer) to oversee compliance with this privacy policy. If
        you have any questions about this privacy policy or how we handle your personal data, please contact the Privacy
        Officer. You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the
        UK supervisory authority for data protection issues.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>
